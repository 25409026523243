<template>
  <div class="promoteDateBox"
       v-loading='data_running'>
    <content-title :title="$t('message.promotion')"></content-title>
    <!-- 顶部搜索 -->
    <div class="search flexCenter-jc-sb flexCenter-ai-center">
      <div class="input_box flexCenter-ai-center">
        <el-date-picker
            class="dataPicker"
            :placeholder="$t('message.promotePlaceholder')"
            v-model="time"
            type="daterange"
            align="right"
            unlink-panels
            size="large"
            range-separator="~"
            :start-placeholder="$t('message.startDate')"
            :end-placeholder="$t('message.endDate')"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd">
        </el-date-picker>

        <div class="search_btn button-me" @click="getList">
          {{ $t("message.search") }}
        </div>
      </div>
    </div>

    <div
        class="list_box" >
      <div class="list_content" v-if="list.length > 0">
        <div class="title flexCenter-ai-center">
          <span class="headerSpan w7">{{ $t("message.promoteDate") }}</span>
          <span class="headerSpan w7">{{ $t("message.promoteAddUserNum") }}</span>
          <span class="headerSpan w7">{{ $t("message.promoteTotalRechargeAmount") }}</span>
          <span class="headerSpan w7">{{ $t("message.promotePassageFee") }}</span>
          <span class="headerSpan w7">{{ $t("message.promoteDividableAmount") }}</span>
          <span class="headerSpan w7">{{ $t("message.promoteShareRatio") }}</span>
          <span class="headerSpan w7">{{ $t("message.promoteActualShareAmount") }}</span>
        </div>
        <div class="list">
          <div
              class="item flexCenter-ai-center"
              v-for="(item, index) in list"
              :key="index"
          >
            <span class="itemSpan w7">{{item.date}}</span>
            <span class="itemSpan w7">{{item.incr_user_num}}</span>
            <span class="itemSpan w7">{{item.total_charge_amount}}</span>
            <span class="itemSpan w7">{{item.channel_fee}}</span>
            <span class="itemSpan w7">{{item.separate_able_amount}}</span>
            <span class="itemSpan w7">{{item.separate_rate}}</span>
            <span class="itemSpan w7">{{item.real_separate_amount}}</span>
          </div>
        </div>
        <div class="pagination" style="padding-bottom: 30px;">
          <el-pagination
              background
              layout="prev, pager, next"
              @current-change="sizeChange"
              :hide-on-single-page="true"
              :total="count"
              :page-size="data.limit"
          >
          </el-pagination>
        </div>
      </div>
      <div class="empty flexCenter flex-column" v-if="noData">
        <img src="@/assets/images/empty.png" alt="" />
        <span style="margin-bottom: 30px;">{{ $t("message.noMoreData") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import contentTitle from "@/components/contentTitle/contentTitle"; //顶部栏
import { channelList } from "@/apis/api";
export default {
  name: "promoteList",
  data() {
    return {
      pickerOptions: {
        shortcuts: [
        {
          text: this.$t('message.yesterday'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: this.$t('message.today'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('message.sevenDay'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('message.fifteenDay'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('message.thirtyDay'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: "",
      count: 0,
      data: {
        page: 1,
        limit: 10,
      },
      list:  [],
      noData: false, // 是否加载无数据布局
      data_running: false, // 加载数据
      is_create: true, // 是否发送请求 开关
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 分页
    sizeChange(v) {
      this.data.page = v;
      this.getList();
    },
    // 获取数据列表
    async getList() {
      this.data_running = true
      let date = ''
      if (this.time && this.time.length>0){
        date = `${this.time[0]}~${this.time[1]}`
      }else {
        date = ''
      }
      let params = {
        date,
        page: this.data.page,
        limit: this.data.limit,
      };
      let res = await channelList(params);
      this.data_running = false
      if (res.code === 1) {
        this.count = Number(res.count);
        this.list = [...res.data];
        res.data.length > 0 ? this.noData = false : this.noData = true
      }
    },
  },
  components: {
    contentTitle,
  },
};
</script>
<style lang="scss" scoped>
.promoteDateBox{
  .promoteHeader{
    background-color: #dcdcdc;
  }
  .search_btn {
    cursor: pointer;
    // width: 80px;
    padding: 0 20px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    color: #fff;
    background-color: #3d76ff;
    border: 1px solid #3d76ff;
    border-radius: 0px 6px 6px 0;
  }
  .list_box{
    margin-top: 30px;
    background-color: #fff;
    border-radius: 8px;
    .list_content{
      .w7{
        width: calc(100% / 7);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
      .headerSpan{
        font-weight: 600;
        color: #15161A;
      }
      .title{
        height: 60px;
        display: flex;
        align-items: center;
      }
      .list{
        .item{
          background-color: #fff;
          &:nth-of-type(odd){
            background-color: #F9F9F9;
          }
          .itemSpan{
            height: 54px;
            color: #32343A;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.promoteDateBox{
  .promoteHeader{
    th{
      background-color: #f9f9f9;
    }
  }
  .dataPicker{
    height: 44px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #3D76FF;
  }
  .el-date-editor .el-range-separator{
    display: flex;
    align-items: center;
  }
}
</style>
